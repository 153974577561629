<template>
  <div class="headMargin">
    <GridTable></GridTable>
  </div>
</template>

<style scoped>
.headMargin {
  margin-top: 100px;
}

.pagination {
  padding-left: 15px;
}
</style>

<script>
import GridTable from '../../components/datatable/Gridtable'

export default {
  components: {
    GridTable,
  },
  data() {
    return {}
  },
  computed: {},

  methods: {}
}
</script>
