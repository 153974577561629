<template>
  <div>
    <b-row class="page-margin">
      <b-col lg="4" class="my-1">
        <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="2"
            label-size="sm"
            class="mb-2">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button @click="getUserData">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card class="form_card">
      <b-table :items="items" :fields="fields" :busy="isBusy" striped responsive="sm">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(id)="data">
        <a style="cursor: pointer" target="_blank" @click="redirectToUserAccount(data.item)" class="text-warning">{{ data.item.id.substring(data.item.id.length - 6) }}</a>
        </template>
        <template #cell(logto_id)="data">
          <a style="cursor: pointer" target="_blank" @click="redirectToUserDetails(data.item)"
             class=" text-decoration-none text-primary">{{ data.item.logto_id }}</a>
        </template>
        <template #cell(mobile)="data">
          <div class="d-flex">
            <p class="text-left text-wrap">{{ data.item.mobile }}</p>
            &nbsp;<b v-if="data.item.whats_app_consent" class="text-success font-weight-bold">
            <i class="font-weight-bold bx bxl-whatsapp"></i>
          </b>
          </div>
        </template>
        <template #cell(created_at)="data">
          <p class="text-left text-wrap">{{ getDateInStr(data.item.created_at) }}</p>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <span><b-btn variant="outline-primary" @click="editItem(item)">Edit</b-btn></span>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <p>Showing {{ (this.pageNumber * this.pageSize) + 1 }} to {{ getEndNumber() }} of {{ this.totalRows }}
            entries </p>
        </b-col>
        <b-col>
          <b-pagination v-model="pageNumber" :per-page="pageSize" :total-rows="totalRows"
                        @change="onPageChange" align="end"></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<style>
.page-margin {
  margin-left: 20px;
  margin-right: 20px;
}
</style>

<script>
const moment = require('moment');
const axios = require('axios');
export default {
  components: {},
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        {key: 'id', label: '#'},
        {key: 'logto_id', label: 'Logto#'},
        {key: 'created_at', label: 'Date'},
        {key: 'email', label: 'Email'},
        {key: 'credits', label: 'Credits'},
        {key: 'mobile', label: 'Mobile'},
        {key: 'normalized_email', label: 'Normalize Email'},
        {key: 'validEmail', label: 'Valid Email'},
        'actions'
      ],
      pageNumber: 1,
      pageSize: 10,
      totalRows: 0,
      filter: null,
    }
  },
  methods: {
    getDateInStr(date) {
      if (!date) {
        return ''
      }
      return moment(new Date(date)).format('DD MMM, YYYY HH:mm')
    },
    redirectToUserDetails(item) {
      let url = `${process.env.VUE_APP_LOGTO_URL}/console/users/${item.logto_id}/settings`
      window.open(url, '_blank');
    },
    async redirectToUserAccount(item) {
      let url = `/users-account/${item.email}`
      await this.$router.push(url)
    },

    editItem(item) {
      this.$router.push(`/user/${item.id}`)
    },
    onPageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getUserData();
    },
    getEndNumber() {
      const first = (this.pageNumber * this.pageSize) + this.pageSize
      return Math.min(this.totalRows, first)
    },
    async getUserData() {
      this.isBusy = true;
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/v1/api/admin/users?page_size=${this.pageSize}&page_number=${this.pageNumber}`;
        if (this.filter) {
          url += `&q=${this.filter}`;
        }
        const {data} = await axios.get(url, {});
        this.items = [];
        for (let i = 0; i < data.value.length; i++) {
          this.items.push({...data.value[i], actions: true})
        }
        this.totalRows = data.pagination.total;
      } catch (e) {
        console.log(e);
      } finally {
        this.isBusy = false
      }
    }
  },
  async mounted() {
    await this.getUserData();
  }
}
</script>

<style scoped>

</style>
